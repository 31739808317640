<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M17.0482 15.3092C16.4378 15.3092 15.8916 15.5502 15.4739 15.9277L9.74699 12.5944C9.78715 12.4096 9.81928 12.2249 9.81928 12.0321C9.81928 11.8394 9.78715 11.6546 9.74699 11.4699L15.4096 8.16867C15.8434 8.57028 16.4137 8.81928 17.0482 8.81928C18.3815 8.81928 19.4578 7.74297 19.4578 6.40964C19.4578 5.07631 18.3815 4 17.0482 4C15.7149 4 14.6386 5.07631 14.6386 6.40964C14.6386 6.60241 14.6707 6.78715 14.7108 6.97189L9.04819 10.2731C8.61446 9.87149 8.04418 9.62249 7.40964 9.62249C6.07631 9.62249 5 10.6988 5 12.0321C5 13.3655 6.07631 14.4418 7.40964 14.4418C8.04418 14.4418 8.61446 14.1928 9.04819 13.7912L14.7671 17.1325C14.7269 17.3012 14.7028 17.4779 14.7028 17.6546C14.7028 18.9478 15.755 20 17.0482 20C18.3414 20 19.3936 18.9478 19.3936 17.6546C19.3936 16.3614 18.3414 15.3092 17.0482 15.3092Z" fill="#02ACEA"/>
  </svg>
</template>
<script>
import Vue from 'vue';

const IconEwShare = Vue.extend({
  name: 'icon-ew-share',
});
export default IconEwShare;
</script>
