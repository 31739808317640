<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.3708 17.3995L16.4305 19.8549C17.174 20.3049 18.0838 19.6397 17.8881 18.7984L16.812 14.1811L20.4022 11.0703C21.0576 10.5029 20.7055 9.42682 19.8446 9.35834L15.1197 8.95726L13.2708 4.59428C12.9382 3.80191 11.8034 3.80191 11.4708 4.59428L9.62193 8.94748L4.89701 9.34856C4.03615 9.41703 3.68399 10.4931 4.33941 11.0605L7.92957 14.1713L6.8535 18.7886C6.65785 19.6299 7.56762 20.2951 8.31109 19.8451L12.3708 17.3995Z" fill="#02ACEA"/>
  </svg>
</template>
<script>
import Vue from 'vue';

const IconEwFavorite = Vue.extend({
  name: 'icon-ew-favorite',
});
export default IconEwFavorite;
</script>
