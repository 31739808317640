<template>

  <svg class="svg-icon" :class="{'svg-icon__active': active }" :width="width" viewBox="0 0 26 26" xmlns="http://www.w3.org/2000/svg">
      <path class="circle"
            d="M13 25C19.6274 25 25 19.6274 25 13C25 6.37258 19.6274 1 13 1C6.37258 1 1 6.37258 1 13C1 19.6274 6.37258 25 13 25Z"
            stroke-width="0.977778"/>
      <path class="figure" fill-rule="evenodd" clip-rule="evenodd"
            d="M8.52146 1.28564L10.8022 5.8465L15.9003 6.58435L12.2109 10.1325L13.083 15.1451L8.52146 12.7752L3.95997 15.1431L4.83202 10.1305L1.14258 6.58435L6.24071 5.84849L8.52146 1.28564Z"
            stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
import Vue from 'vue';

const IconStar = Vue.extend({
  name: 'icon-star',
  props: {
    color: {
      type: String,
      default: '#00B6F8',
    },
    active: {
      type: Boolean,
      default: false,
    },
    width: {
      type: Number,
      default: 26
    }
  },
  computed: {
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    style() {
      return `stroke: ${this.color}; fill:${this.color}}`;
    },
  },
});

export default IconStar;
</script>

<style scoped lang="scss">
  .svg-icon {
    fill: none;

    .circle {
      stroke: $ew-black;
    }

    .figure {
      stroke: $ew-black;
      transform: translate(5px, 4px);
    }

    &:hover {

      .circle {
        stroke: $ew-blue;
      }

      .figure {
        stroke: $ew-blue;
      }
    }
  }

  .svg-icon__active {
    fill: none;

    .circle {
      stroke: $ew-blue;
    }

    .figure {
      stroke: $ew-blue;
    }
  }
</style>
