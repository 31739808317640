<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M15.1129 8.94445L19.8447 9.35507C20.705 9.4235 21.0472 10.4989 20.3922 11.0659L16.8042 14.1749L17.8796 18.7991C18.0752 19.6399 17.1659 20.3047 16.4229 19.855L12.3657 17.4011L8.30845 19.8452C7.56544 20.2949 6.65623 19.6301 6.85176 18.7894L7.92717 14.1749L4.3392 11.0659C3.68418 10.4989 4.03613 9.4235 4.89646 9.35507L9.6185 8.95423L11.4663 4.59392C11.7987 3.80203 12.9327 3.80203 13.2651 4.59392L15.1129 8.94445ZM8.68983 17.7921L12.3658 15.5729L16.0515 17.8019L15.0739 13.6176L18.3197 10.802L14.0376 10.4305L12.3658 6.48076L10.7038 10.4207L6.42168 10.7922L9.66747 13.6078L8.68983 17.7921Z" fill="#02ACEA"/>
  </svg>
</template>
<script>
import Vue from 'vue';

const IconEwUnfavorite = Vue.extend({
  name: 'icon-ew-unfavorite',
});
export default IconEwUnfavorite;
</script>
