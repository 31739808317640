import { TEvent } from '@/_types/event.type';
import { TConferenceProgram } from '@/_modules/promo/types/conference-program.type';
import { ConferenceProgramChatType } from '@/_modules/promo-program/types/conference-program-chat-type.enum';
// import { TChatGroup } from '@/_modules/chat/types/chat-group.type';
// import { TConferenceProgram } from '@/_modules/promo/types/conference-program.type';
// import { ConferenceProgramChatType } from '@/_modules/promo-program/types/conference-program-chat-type.enum';

export default class ChatHelper {

  public static getEventChatGroupId(event: TEvent): string {
    if (!event || !event.id) {
      return null;
    }
    return 'main';
  }

  public static getProgramChatGroupId(program: TConferenceProgram, chatType: ConferenceProgramChatType): string {
    if (!program || !program.id) {
      return null;
    }
    // TODO: prepend 'event_[eventId]_' ?
    return `program_${program.id}_${chatType}`;
  }

  public static isUrlOfImage(url: string): boolean {
    if (!url || (typeof url !== 'string')) {
      return false;
    }

    const imageExtensions: string[] = ['.jpg', '.jpeg', '.png', '.jp2', '.j2k', '.jpf', '.jpm', '.jpg2', '.j2c', '.jpc', '.jpx', '.gif', '.png', '.webp'];
    url = url.toLowerCase();

    for (let i = 0; i < imageExtensions.length; i++) {
      const imageExtension = imageExtensions[i];
      if (url.indexOf(imageExtension) === (url.length - imageExtension.length)) {
        return true;
      }
    }

    return false;
  }

  // TODO: merge createLinksWithImagePreviews and createLinks into one method with options object as parameter
  public static createLinksWithImagePreviews(text: string): string {
    const urlRegex = /(https?:\/\/[^\s]+)/g;

    if (text) {
      return text.replace(urlRegex, (url: string) => {
        // TODO: make attribute class="" not fixed, maybe accept from options object param
        if ( this.isUrlOfImage(url) ) {
          return '<a target="_blank" class="parsed-link parsed-link-with-image chat-image" href="' + url + '">' + '<img width="100px" src="' + url + '" alt="" />' + '</a>';
        } else {
          return '<a target="_blank" class="parsed-link" href="' + url + '">' + url + '</a>' + '<br/>';
        }
      });
    }
    return '';
  }

  public static createLinks(text: string): string {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    if (text) {
      return text.replace(urlRegex, function(url: string) {
        // TODO: make attribute class="", maybe accept from options object param
        return '<a target="_blank" class="parsed-link" href="' + url + '">' + url + '</a>' + '<br/>';
      });
    }
    return '';
  }

  // TODO: review -------------------------------------------------

  // public static getEventChatGroup(event: TEvent): TChatGroup {
  //   if (!event || !event.id) {
  //     return null;
  //   }
  //   const groupId = ChatHelper.getEventChatGroupId(event);
  //   if (!groupId) {
  //     return null;
  //   }
  //   return {
  //     id: groupId,
  //     name: event.title || '',
  //   };
  // }
  //
  // public static getProgramChatGroup(program: TConferenceProgram, chatType: ConferenceProgramChatType): TChatGroup {
  //   if (!program || !program.id) {
  //     return null;
  //   }
  //   const groupId = ChatHelper.getProgramGroupId(program, chatType);
  //   if (!groupId) {
  //     return null;
  //   }
  //   return {
  //     id: groupId,
  //     name: program.title || '',
  //   };
  // }
  //
  // public static getUserId(config: TChatConfig): string {
  //   if (!config || !config.event || !config.contact) {
  //     return null;
  //   }
  //   return `event_${config.event.id}_contact_${config.contact.id}`;
  // }
  //
  // public static getProgramGroupId(program: TConferenceProgram, chatType: ConferenceProgramChatType): string {
  //   if (!program || !program.id) {
  //     return null;
  //   }
  //   // TODO: prepend 'event_[eventId]_'
  //   return `program_${program.id}_${chatType}`;
  // }

}
