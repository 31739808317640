var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"event-map"},[(_vm.currentPromoPage)?_c('div',{ref:"companySeatPlanPopup",staticClass:"companies-wrapper",class:{
        'show-left': _vm.popupShowLeft,
        'show-top': _vm.popupShowTop,
      },style:(("top: " + _vm.clientY + "px; left: " + _vm.clientX + "px"))},[_c('div',{staticClass:"company"},[_c('div',{staticClass:"avatar"},[_c('img',{attrs:{"src":_vm.currentPromoPageAvatarSrc,"alt":""}})]),_c('div',{staticClass:"content"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.currentPromoPage.title))]),_c('div',{staticClass:"info"})])]),_c('div',{staticClass:"description"},[_c('truncate',{attrs:{"action-class":"more-btn","length":90,"less":_vm.$t('button.less'),"clamp":_vm.$t('button.more'),"type":"html","text":_vm.linkifyUrls(_vm.currentPromoPage.description || '')}})],1),_c('div',{staticClass:"action-btns"},[_c('div',{staticClass:"l-side"},[_c('div',{staticClass:"link",on:{"click":function($event){return _vm.onShowCompanyClick(_vm.currentPromoPage.external_id)}}},[_c('icon-message')],1),_c('div',{staticClass:"link",on:{"click":_vm.toggleFavorite}},[_c('icon-star',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                content: _vm.currentPromoPage.is_favorite ? _vm.$t('button.rm_favorites') : _vm.$t('button.favorites'),
                classes: ['vue-tooltip-dark'],
                placement: 'bottom',
                delay: 0,
              }),expression:"{\n                content: currentPromoPage.is_favorite ? $t('button.rm_favorites') : $t('button.favorites'),\n                classes: ['vue-tooltip-dark'],\n                placement: 'bottom',\n                delay: 0,\n              }"}],attrs:{"active":_vm.currentPromoPage.is_favorite}})],1)]),_c('div',{staticClass:"r-side"},[_c('a',{staticClass:"link",on:{"click":function($event){return _vm.onShowCompanyClick(_vm.currentPromoPage.external_id)}}},[_vm._v(" "+_vm._s(_vm.$t('button.show_company'))+" ")])])])]):_vm._e(),(_vm.planIsLoading || _vm.promoPageListLoading)?_c('div',{staticClass:"loading"},[_c('ew-circular-loading',{attrs:{"width":22}})],1):_vm._e(),(_vm.isSeatPlanVisible)?_c('div',{staticClass:"seatplan-viewer-container online"},[_c('iframe',{ref:"setPlanIframe",staticClass:"seat-plan-iframe",attrs:{"src":_vm.seatPlanIframeSrc},on:{"load":_vm.onSeatPlanIframeLoad}})]):(_vm.event && _vm.event.map_image)?_c('div',{staticClass:"map-image-viewer"},[_c('img',{attrs:{"src":_vm.event.map_image,"alt":""}})]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }