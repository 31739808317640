import { Vue, Component } from 'vue-property-decorator';
import NotificationsHelper from '@/_helpers/notifications.helper';

@Component
export default class NotificationsMixin extends Vue {

  public prepareBadgeNotificationNumber(count: number): string {
    return NotificationsHelper.prepareBadgeNotificationNumber(count);
  }

}
